/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment, useCallback, useEffect, useState } from 'react';

import { EngagementSpotlight, useCoordination } from '@atlassiansox/engagekit-ts';
import { css, jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';

import { PrimaryButton, type PrimaryButtonProps } from '@atlaskit/atlassian-navigation';
import { type ButtonProps } from '@atlaskit/button';
import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { RovoIcon } from '@atlaskit/logo';
import { SpotlightManager, SpotlightPulse, SpotlightTarget } from '@atlaskit/onboarding';
import { fg } from '@atlaskit/platform-feature-flags';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useAnalytics } from '@atlassian/conversation-assistant-instrumentation';
import {
	ConversationAssistantOnboardingSpotlight,
	SPOTLIGHT_ONBOARDING_TARGET,
	useConversationAssistantSpotlightActions,
	useConversationAssistantSpotlightState,
	useCoordinationClient,
} from '@atlassian/conversation-assistant-onboarding';
import { TopNavButton } from '@atlassian/navigation-system/experimental/top-nav-button';

import { ChatIconAnimated } from './chat-icon-animated';
import { messages } from './messages';
import { RovoIconAnimated } from './rovo-icon-animated';

const gradientBorderStyles = css({
	content: '""',
	position: 'absolute',
	top: token('space.0'),
	left: token('space.0'),
	right: token('space.0'),
	bottom: token('space.0'),
	borderRadius: '5px',
	paddingTop: token('space.025'),
	paddingRight: token('space.025'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.025'),
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	background: 'linear-gradient(45deg, #0065FF, #BF63F3, #FFA900)',
	mask: 'linear-gradient(#000 0 0) exclude, linear-gradient(#000 0 0) content-box',
	opacity: 0,
	transition: 'opacity 0.3s',
	'&:hover': {
		opacity: 1,
	},
});

type Props = {
	testId?: string;
	isActive: boolean;
	isDisabled?: boolean;
	onClick: ButtonProps['onClick'];
	tooltipContent?: React.ReactNode;
	siteId: string;
	productKey: string;
};

const defaultButtonStyles = {
	paddingInline: '8px',
};

const animatedButtonStyles = {
	paddingInline: '12px',
	position: 'relative' as const,
	overflow: 'hidden',
	backgroundClip: 'padding-box',
};

const GradientBorderOnHover = () => <span css={gradientBorderStyles} />;

const inactiveButtonStyles = {
	background: token('color.background.neutral'),
};

const disabledButtonStyles = {
	background: token('color.background.disabled'),
	color: token('color.text.disabled'),
};

type ConditionalWrapperChildren = React.PropsWithChildren<any> | null;

export const ConditionalWrapper = ({
	condition,
	wrapper,
	children,
}: {
	condition: boolean;
	wrapper: (children: ConditionalWrapperChildren) => ConditionalWrapperChildren;
	children: ConditionalWrapperChildren;
}) => (condition ? wrapper(children) : children);

type ButtonComponentProps = {
	onMouseEnter?: React.MouseEventHandler;
	style?: React.CSSProperties;
	iconBefore?: React.ReactChild;
	testId?: string;
	isSelected?: boolean;
	onClick?: PrimaryButtonProps['onClick'];
	isDisabled?: boolean;
	tooltip?: React.ReactNode;
	children: React.ReactNode;
};

type RovoButtonExperimentValues =
	| 'control'
	| 'not-enrolled'
	| 'chat-icon-chat-text'
	| 'chat-icon-rovo-text'
	| 'rovo-icon'
	| 'chat-icon';

const getConversationAssistantButton =
	(Button: React.ComponentType<ButtonComponentProps> = PrimaryButton) =>
	({
		testId,
		isActive,
		isDisabled = false,
		onClick: onClickProp,
		tooltipContent,
		siteId,
		productKey,
	}: Props) => {
		di(useCoordination, useCoordinationClient, FeatureGates);
		const { formatMessage } = useIntl();
		const { start } = useConversationAssistantSpotlightActions();
		const [shouldShowMessage, setShouldShowMessage] = useState(false);
		const messageId =
			productKey === 'confluence'
				? 'confluence_rovo_chat_agents_spotlight_ga'
				: productKey === 'jira'
					? 'jira_rovo_chat_agents_spotlight_ga'
					: '';
		const shouldUseCoordination = !!messageId;
		const { sendAnalyticsEvent } = useAnalytics();

		const openRovoChat = (
			event: React.MouseEvent<HTMLElement, MouseEvent>,
			analyticsEvent: any,
		) => {
			if (!isActive && onClick) {
				onClick(event, analyticsEvent);
			}
		};

		const onClick = useCallback<NonNullable<typeof onClickProp>>(
			(...params) => {
				sendAnalyticsEvent({
					eventType: 'ui',
					action: 'clicked',
					actionSubject: 'rovoChatButton',
					attributes: {
						isActive,
						isDisabled,
						productKey,
					},
				});

				return onClickProp?.(...params);
			},
			[isActive, isDisabled, onClickProp, productKey, sendAnalyticsEvent],
		);

		const getChildren = () => {
			const experimentCohort = FeatureGates.getExperimentValue<RovoButtonExperimentValues>(
				'rovo_chat_button_appearance_experiment_userid',
				'appearance',
				'not-enrolled',
			);

			const buttonProps = {
				onMouseEnter: () => {
					if (shouldShowMessage) {
						sendAnalyticsEvent({
							eventType: 'ui',
							action: 'hovered',
							actionSubject: 'rovoChatButtonSpotlight',
						});
						start();
					}
				},
				testId: testId,
				isSelected: isActive,
				onClick: onClick,
				isDisabled: isDisabled,
				tooltip: tooltipContent ?? <Fragment>{formatMessage(messages.buttonLabel)}</Fragment>,
			};

			if (fg('rovo_chat_new_chat_icon_enabled')) {
				return (
					<Button
						{...buttonProps}
						style={{
							/* eslint-disable @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
							...defaultButtonStyles,
							...(!isActive && inactiveButtonStyles),
							...(isDisabled && disabledButtonStyles),
							/* eslint-enable @atlaskit/ui-styling-standard/enforce-style-prop */
						}}
						iconBefore={<ChatIconAnimated showStaticIcon isSelected={isActive} />}
					>
						{formatMessage(messages.buttonLabel)}
					</Button>
				);
			}

			switch (experimentCohort) {
				case 'chat-icon-chat-text':
					return (
						<Fragment>
							<Button
								{...buttonProps}
								// Remove style usage after nav v3 button is removed
								style={{
									/* eslint-disable @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
									...animatedButtonStyles,
									...(!isActive && inactiveButtonStyles),
									...(isDisabled && disabledButtonStyles),
									/* eslint-enable @atlaskit/ui-styling-standard/enforce-style-prop */
								}}
								iconBefore={<ChatIconAnimated />}
							>
								{formatMessage(messages.buttonLabel)}
								<GradientBorderOnHover />
							</Button>
						</Fragment>
					);
				case 'chat-icon-rovo-text':
					return (
						<Button
							{...buttonProps}
							// Remove style usage after nav v3 button is removed
							style={{
								/* eslint-disable @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
								...animatedButtonStyles,
								...(!isActive && inactiveButtonStyles),
								...(isDisabled && disabledButtonStyles),
								/* eslint-enable @atlaskit/ui-styling-standard/enforce-style-prop */
							}}
							iconBefore={<ChatIconAnimated />}
							tooltip={tooltipContent ?? formatMessage(messages.rovoChatButtonLabel)}
						>
							{formatMessage(messages.rovoChatButtonLabel)}
							<GradientBorderOnHover />
						</Button>
					);
				case 'rovo-icon':
					return (
						<IconButton
							{...buttonProps}
							shape="circle"
							icon={() => <RovoIconAnimated />}
							label={formatMessage(messages.buttonLabel)}
							isTooltipDisabled={false}
							// use default IconButton tooltip
							tooltip={undefined}
						/>
					);
				case 'chat-icon':
					return (
						<IconButton
							{...buttonProps}
							shape="circle"
							icon={() => <ChatIconAnimated />}
							label={formatMessage(messages.buttonLabel)}
							isTooltipDisabled={false}
							// use default IconButton tooltip
							tooltip={undefined}
						/>
					);
				case 'control':
				case 'not-enrolled':
				default:
					return (
						<Button
							{...buttonProps}
							style={{
								/* eslint-disable @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
								...defaultButtonStyles,
								...(!isActive && inactiveButtonStyles),
								...(isDisabled && disabledButtonStyles),
								/* eslint-enable @atlaskit/ui-styling-standard/enforce-style-prop */
							}}
							iconBefore={<RovoIcon size="xsmall" label="" />}
						>
							{formatMessage(messages.buttonLabel)}
						</Button>
					);
			}
		};

		return shouldUseCoordination ? (
			<SpotlightWrapper
				setShouldShowMessage={setShouldShowMessage}
				messageId={messageId}
				siteId={siteId}
				children={getChildren()}
				shouldShowMessage={shouldShowMessage}
				openRovoChat={openRovoChat}
			/>
		) : (
			getChildren()
		);
	};

export const ConversationAssistantButton = getConversationAssistantButton(PrimaryButton);
export const ConversationAssistantButtonNav4 = getConversationAssistantButton(function Nav4Button({
	tooltip,
	...props
}: ButtonComponentProps) {
	return (
		<Tooltip
			content={tooltip}
			// This button's visible text matches the tooltip
			// Announcing the tooltip means 'Chat' would be announced twice
			isScreenReaderAnnouncementDisabled
		>
			{({ ref, ...triggerProps }) => (
				<TopNavButton
					ref={ref}
					{...triggerProps}
					{...props}
					onClick={(event, analyticsEvent) => {
						props.onClick?.(event, analyticsEvent);
						triggerProps.onClick?.(event);
					}}
					iconBefore={(() => props.iconBefore || null) as IconButtonProps['icon']}
				/>
			)}
		</Tooltip>
	);
});

export const SpotlightWrapper = ({
	children,
	messageId,
	siteId,
	setShouldShowMessage,
	shouldShowMessage,
	openRovoChat,
}: {
	children: ConditionalWrapperChildren;
	messageId: string;
	siteId: string;
	setShouldShowMessage: React.Dispatch<React.SetStateAction<boolean>>;
	shouldShowMessage: boolean;
	openRovoChat: (event: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: any) => void;
}) => {
	const { activeSpotlight } = useConversationAssistantSpotlightState();
	const coordinationClient = useCoordinationClient(siteId);
	const [onboardingStarted, stopOnboarding] = useCoordination(coordinationClient, messageId);
	const { sendAnalyticsEvent } = useAnalytics();

	const onDismissMessage = () => {
		setShouldShowMessage(false);
		stopOnboarding();
	};

	useEffect(() => {
		if (shouldShowMessage && activeSpotlight !== null) {
			sendAnalyticsEvent({
				eventType: 'ui',
				action: 'shown',
				actionSubject: 'rovoChatButtonSpotlight',
			});
		}
	}, [shouldShowMessage, activeSpotlight, sendAnalyticsEvent]);

	useEffect(() => {
		if (onboardingStarted) {
			setShouldShowMessage(true);
		}
	}, [onboardingStarted, setShouldShowMessage]);
	return (
		<ConditionalWrapper
			condition={shouldShowMessage}
			wrapper={(children) => (
				<SpotlightManager blanketIsTinted>
					<SpotlightTarget name={SPOTLIGHT_ONBOARDING_TARGET}>
						<SpotlightPulse radius={3} pulse={activeSpotlight === null}>
							{children}
							<ConversationAssistantOnboardingSpotlight
								onDismiss={onDismissMessage}
								openRovoChat={openRovoChat}
							/>
							<EngagementSpotlight engagementId={SPOTLIGHT_ONBOARDING_TARGET} />
						</SpotlightPulse>
					</SpotlightTarget>
				</SpotlightManager>
			)}
		>
			{children}
		</ConditionalWrapper>
	);
};
