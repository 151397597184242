import React, {
	type ComponentType,
	type ReactNode,
	type Ref,
	type RefObject,
	useCallback,
	useContext,
	useMemo,
} from 'react';

import { type EntryPointProps, loadEntryPoint } from 'react-relay';
import { useMergeRefs } from 'use-callback-ref';

import { SkeletonItem } from '@atlaskit/menu';
import Modal, {
	ModalBody,
	type ModalDialogProps,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '@atlaskit/modal-dialog';
import { Stack } from '@atlaskit/primitives';
import { useEntryPointConfig } from '@atlassian/entry-point-config';
import {
	type ModalComponentProps,
	ModalContext,
} from '@atlassian/entry-point-modal-context/ModalContext';
import type {
	AnyEntryPoint,
	EntryPointRef,
	GetRuntimePropsFromEntryPoint,
	ParamsOfEntryPoint,
} from '@atlassian/entry-point-types';
import { usePressablePreloadRef } from '@atlassian/entry-point-use-trigger';
import { InternalEntryPointContainer } from '@atlassian/internal-entry-point-container';
import type { JSResourceReference } from '@atlassian/react-async';
import { useRelayEnvironmentProvider } from '@atlassian/relay-environment-provider';

export type ModalEntryPointProps<
	TPreloadedQueries,
	TNestedEntryPoints,
	TRuntimeProps,
	TExtraProps,
> = EntryPointProps<
	TPreloadedQueries,
	TNestedEntryPoints,
	TRuntimeProps & ModalComponentProps,
	TExtraProps
>;

type EntryPointPropsType<TEntryPoint> = GetRuntimePropsFromEntryPoint<TEntryPoint>;

export type ModalTriggerProps<TEntryPoint> = {
	children: ({ ref }: { ref: RefObject<HTMLElement> }) => ReactNode;
	entryPoint: TEntryPoint;
	entryPointParams?: ParamsOfEntryPoint<TEntryPoint>;
	entryPointProps?: Omit<
		EntryPointPropsType<TEntryPoint>,
		| 'onClose'
		| 'setShouldCloseOnOverlayClick'
		| 'setShouldCloseOnEscapePress'
		| 'shouldCloseOnOverlayClick'
		| 'shouldCloseOnEscapePress'
	> & {
		onClose?: () => void;
	};
	Fallback?: ComponentType<{ onClose?: () => void }>;
	title?: ReactNode;
	modalProps?: ModalDialogProps;
	forwardedRef?: Ref<HTMLElement>;
};

const emptyEntryPointParams = {} as ParamsOfEntryPoint<AnyEntryPoint>;
const emptyEntryPointProps = {} as EntryPointPropsType<AnyEntryPoint>;

export function ModalTrigger<TEntryPoint extends AnyEntryPoint>({
	children,
	entryPoint,
	entryPointParams,
	entryPointProps,
	Fallback,
	title,
	modalProps,
	forwardedRef,
}: ModalTriggerProps<TEntryPoint>) {
	const { openModal } = useContext(ModalContext);
	const environmentProvider = useRelayEnvironmentProvider();
	const { ModalErrorFallback } = useEntryPointConfig();

	const metricName = useMemo((): string => {
		return (entryPoint.root as JSResourceReference<unknown>).getModuleName() || 'unknown';
	}, [entryPoint]);

	const load = useCallback(
		() =>
			loadEntryPoint<TEntryPoint>(
				environmentProvider,
				entryPoint,
				entryPointParams ?? emptyEntryPointParams,
			),
		[entryPoint, entryPointParams, environmentProvider],
	);

	const onLoad = useCallback(
		({
			reference: entryPointReference,
			dispose,
		}: {
			reference: EntryPointRef<TEntryPoint>;
			dispose: () => void;
		}) => {
			openModal(
				({
					onClose: closeModal,
					shouldCloseOnOverlayClick,
					shouldCloseOnEscapePress,
					setShouldCloseOnOverlayClick,
					setShouldCloseOnEscapePress,
				}) => {
					const onClose = () => {
						entryPointProps?.onClose?.();
						closeModal();
						dispose();
					};

					const defaultFallback = (
						<>
							<ModalHeader>
								{title ? (
									<ModalTitle>{title}</ModalTitle>
								) : (
									<Stack grow="fill">
										<SkeletonItem />
									</Stack>
								)}
							</ModalHeader>
							<ModalBody>
								<SkeletonItem />
								<SkeletonItem />
								<SkeletonItem />
							</ModalBody>
							<ModalFooter />
						</>
					);

					const container = (
						<InternalEntryPointContainer
							entryPointReference={entryPointReference}
							errorFallback={({ error }: { error: Error }) => <ModalErrorFallback error={error} />}
							onError={() => closeModal()}
							fallback={Fallback ? <Fallback onClose={closeModal} /> : defaultFallback}
							id={metricName}
							placeholderName="modal-entry-point-pressable-trigger-container"
							runtimeProps={
								{
									...(entryPointProps ?? emptyEntryPointProps),
									onClose,
									setShouldCloseOnOverlayClick,
									setShouldCloseOnEscapePress,
									shouldCloseOnOverlayClick,
									shouldCloseOnEscapePress,
								} as unknown as EntryPointPropsType<TEntryPoint>
							}
						/>
					);

					return (
						<Modal
							{...modalProps}
							onClose={onClose}
							shouldCloseOnOverlayClick={
								shouldCloseOnOverlayClick ?? modalProps?.shouldCloseOnOverlayClick
							}
							shouldCloseOnEscapePress={
								shouldCloseOnEscapePress ?? modalProps?.shouldCloseOnEscapePress
							}
						>
							{container}
						</Modal>
					);
				},
			);
		},
		[openModal, title, modalProps, Fallback, metricName, entryPointProps, ModalErrorFallback],
	);

	const triggerRef = usePressablePreloadRef<EntryPointRef<TEntryPoint>>({
		load,
		onLoad,
	});

	const mergedRef = useMergeRefs([triggerRef, forwardedRef || null]);

	return children({ ref: mergedRef as unknown as RefObject<HTMLElement> });
}
