import React from 'react';

import { type EntryPoint, type EntryPointComponent } from 'react-relay';

import { Flex } from '@atlaskit/primitives';
import { type ModalTriggerProps } from '@atlassian/entry-point-modal-trigger';

import { DismissMenu } from '../../edition-awareness-dismiss-button';

import { ModalEntryPoint } from './modal-entry-point';

type AnyEntryPoint = EntryPoint<EntryPointComponent<any, any, any, any>, any>;

export function DismissibleModalEntryPoint<TEntryPoint extends AnyEntryPoint>(
	props: Omit<ModalTriggerProps<TEntryPoint>, 'children'>,
) {
	return (
		<Flex alignItems="center">
			<ModalEntryPoint {...props} />
			<DismissMenu />
		</Flex>
	);
}
